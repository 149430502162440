<template>
  <v-main>
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
          <h1>eQTLs Interface for Candida albicans</h1>
          <h3>All content was provided by Abhilash Kannan</h3>
          <p class="body-1">
            This is the web interface containing eQTL data from Candida
            albicans.
          </p>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col cols="12">
          <h3>Citing this Web site in a bibliography</h3>
          <p class="body-1"><em>Some citation link..</em></p>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col cols="12">
          <p class="body-1">
            This web interface was developed by Roman Mylonas at 
            <a href="https://www.vital-it.ch/">Vital-It</a>
            &nbsp;/&nbsp; 
            <a href="https://www.sib.swiss/">Swiss Institute of Bioinformatics (SIB)</a>.
          </p>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col cols="12">
          <h5>Release date {{ releaseDate }}</h5>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Home",

  data: () => ({
    releaseDate: "22/12/2021",
  }),
};
</script>
